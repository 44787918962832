function getCookie(cname: string) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
		let c = ca[i];
	  	while(c.charAt(0) === ' ') {
		c = c.substring(1);
	}
	if(c.indexOf(name) === 0) {
		return c.substring(name.length, c.length);
		}
	}
	return '';
}
export function deleteCookie() {
	document.cookie = '__okie-slol=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getSessionToken() {
	const token = getCookie("__okie-slol");
	return token;
}

export async function createUserDetails(arg: any) {
	const data = {
		...arg,
		id: await (await fetch('https://uuid.rocks/plain')).text()
	}
	return data;
}

export function getJwt(request: Request) {
	const authHeader = request.headers.get('Authorization');
	if(!authHeader || authHeader.substring(0, 6) !== 'Bearer') return null;
	return authHeader.substring(6).trim();
}