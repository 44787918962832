import { ReactElement, useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import '../assets/App.css';
import { useAuth } from '../providers/Auth';

export default function Home() {
	const { token, logout } = useAuth();
	const [ user, setUser ] = useState<any>({});
	const [ hover, setHover ] = useState(false);
	const { name, avatarUrl, accent } = user;

	function doLogout() {
		logout();
		window.location.reload()
	}

	useEffect(() => {
		async function getUser() {
			const res = await fetch('/user', {
				headers: { 'Authorization': `Bearer ${token}` }
			});
			const data = await res.json();
			setUser(data);
		}
		if(token) getUser();
	}, [token]);

	let logButton: ReactElement<any, any>;
	if(name) logButton = <LogOutButton onClick={doLogout} />
	else logButton = <LoginButton />

	const logoStyle = (color?: number) => {
		return {
			logo: {
				height: '10em',
				padding: '0em',
				'will-change': 'filter',
				'border-radius': '50%'
			},
			logoHover: {
				filter: `drop-shadow(0 0 2em ${decimalToRgb(color || 11222271)})`
			}
		}
	};

	return (
		<div id="main">
			<img src={avatarUrl ? `${avatarUrl}?size=512` : logo} onMouseEnter={() => {
				setHover(true);
			}} onMouseLeave={() => {
				setHover(false);
			}} style={{ ...logoStyle().logo, ...(hover ? logoStyle(accent).logoHover : null)}} alt={name || 'logo'} />
			<h1>{name ? `hi ${name}! I like your avatar 😍` : 'hey stranger! welcome 😎'}</h1>
			<div className="card">
				<a href="https://discord.gg/f6v2hDSzHR"><button type="button">🤔 my discord</button></a>
				{logButton}
			</div>
			<p className="mid">portfolio of work coming soon. watch this space...</p>
			<p className="bottom">add me on Discord (<code>@sludge</code>) if you need work done!</p>
		</div>
	);
}

function LoginButton() {
	return (
		<a href="/auth/login"><button type="button">who are you? 🤭</button></a>
	)
}

function LogOutButton(props: any) {
	return (
		<button type="button" onClick={props.onClick}>run away? 🥺</button>
	)
}

function decimalToRgb(dec: number) {
	const r = (dec & 0xff0000) >> 16;
	const g = (dec & 0x00ff00) >> 8;
	const b = (dec & 0x0000ff);

	return `rgb(${r}, ${g}, ${b})`
}